<script setup lang="ts">
const [numberValue] = defineModel({
  set(value: string) {
    value = value.replace(/,/g, ".");
    let number = Number(value);
    if (Number.isNaN(number)) {
      return 0;
    }
    return number;
  },
});
</script>

<template>
  <div class="flex items-end">
    <UInput
      :ui="{
        base: 'text-right',
      }"
      v-model="numberValue"
      autocomplete="off"
      v-bind="$attrs">
    </UInput>
    <slot name="trailing"> </slot>
  </div>
</template>

<style scoped></style>
