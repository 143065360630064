<script setup>
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  ui: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["update:modelValue"]);

const selected = ref(props.modelValue);
watch(selected, (value) => {
  emit("update:modelValue", value);
});

watch(
  () => props.modelValue,
  (value) => {
    selected.value = value;
  },
);

const coinStore = useCoinStore();

const search = async (query) => {
  const result =
    query === ""
      ? (coinStore.coins || []).slice(0, 10)
      : coinStore.coins
          .filter((coin) => {
            return (
              coin.symbol.toLowerCase().includes(query.toLowerCase()) ||
              coin.name.toLowerCase().includes(query.toLowerCase())
            );
          })
          .slice(0, 10);

  return result;
};
</script>

<template>
  <USelectMenu
    :searchable="search"
    searchable-placeholder="Search a coin..."
    placeholder="Select a coin"
    class="flex-grow"
    v-model="selected"
    :ui="ui">
    <template #label>
      <div class="flex items-center justify-between gap-8">
        <div class="flex items-center gap-2">
          <UAvatar
            v-if="selected?.fiat"
            :icon="
              selected?.symbol === 'NOK'
                ? 'i-tabler-currency-krone-swedish'
                : selected?.symbol === 'EUR'
                  ? 'i-material-symbols-euro'
                  : 'i-tabler-currency-dollar'
            "
            size="2xs" />

          <UAvatar
            v-else
            :src="`https://s2.coinmarketcap.com/static/img/coins/64x64/${selected?.coinId}.png`"
            size="xs" />

          {{ selected?.symbol }}
        </div>
        <div>
          <UBadge
            v-if="selected?.fiat"
            size="xs"
            variant="outline"
            color="black"
            :ui="{ rounded: 'rounded-full' }"
            class="px-2 text-xs"
            >Fiat</UBadge
          >
        </div>
      </div>
    </template>
    <template #option="{ option: coin }">
      <div class="flex items-center justify-between gap-8">
        <div class="flex items-center gap-2">
          <UAvatar
            v-if="coin.fiat"
            :icon="
              coin.symbol === 'NOK'
                ? 'i-tabler-currency-krone-swedish'
                : coin.symbol === 'EUR'
                  ? 'i-material-symbols-euro'
                  : 'i-tabler-currency-dollar'
            "
            size="2xs" />

          <UAvatar
            v-else
            :src="`https://s2.coinmarketcap.com/static/img/coins/64x64/${coin?.coinId}.png`"
            size="xs" />

          {{ coin.symbol }}
        </div>
        <div>
          <UBadge
            v-if="coin.fiat"
            size="xs"
            variant="outline"
            color="black"
            :ui="{ rounded: 'rounded-full' }"
            class="px-2 text-xs"
            >Fiat</UBadge
          >
        </div>
      </div>
    </template>
  </USelectMenu>
</template>
